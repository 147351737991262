













































































import { app as microsoftTeams } from "@microsoft/teams-js"
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import Loading from "@/components/Loading.vue"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { Project } from "@/models/Project"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { getMeetingDeepLinkUrl } from "@/utilities/URIHelper"

const sessionKey = "PersonalSearch.SearchWord"

interface SearchResult {
  meeting: {
    project: Project
    parent: MeetingStructure
    meeting: Meeting
  }
  name: string
  date: string
  parentId: string
  id: string
  summary: string
  linkUrl: string
  loadingSummary: boolean
}

interface State {
  searchQuery: string
  currentSearchQuery: string
  total: number
  isSearching: boolean
  searchResults: Array<SearchResult>
  meetingMemo: { [key: string]: string }
  page: number
}

export default defineComponent({
  components: {
    I18nFormattedDateTime,
    I18nFormattedMessage,
    Icon,
    Loading,
  },
  setup() {
    const { formatDateTime, formatMessage } = i18nContainer.useContainer()
    const {
      getMyProjectsAsync,
      searchProjectMemosAsync,
    } = projectsContainer.useContainer()
    const { getMemoHtmlValue } = meetingContainer.useContainer()
    const state = reactive<State>({
      searchQuery: (localStorage || sessionStorage).getItem(sessionKey) || "",
      currentSearchQuery: "",
      total: 0,
      isSearching: false,
      searchResults: [],
      meetingMemo: {},
      page: 1,
    })

    watch(
      () => state.searchQuery,
      newValue => {
        ;(localStorage || sessionStorage).setItem(sessionKey, newValue)
      }
    )

    watch(
      () => state.meetingMemo,
      newValue => {
        state.searchResults = state.searchResults.map(v =>
          newValue[v.id]
            ? v
            : Object.assign(v, {
                loadingSummary: false,
                summary: newValue[v.id],
              })
        )
      }
    )

    const getNextAndPastMeetingItems = (
      meetings: Array<{
        project: Project
        meetings: MeetingStructure[]
      }>,
      resultKeyValue: Array<{
        listId: string
        id: string
        summary: string
        fields: { [key: string]: string }
      }>
    ): Array<SearchResult> => {
      const meeteintParentPaire = meetings
        .map(m => m.meetings.map(mi => ({ project: m.project, parent: mi })))
        .reduce((a, b) => {
          return a.concat(b)
        }, [])
        .map(m =>
          m.parent.meetings.map(mi => ({
            project: m.project,
            parent: m.parent,
            meeting: mi,
          }))
        )
        .reduce((a, b) => {
          return a.concat(b)
        }, [])

      const sortedItems = resultKeyValue.reduce(
        (acc: Array<SearchResult>, item) => {
          const meeting = meeteintParentPaire.find(
            m =>
              m.project.sharepointListId === item.listId &&
              m.meeting.sharepointItemId === item.id
          )
          if (meeting) {
            const linkUrl = getMeetingDeepLinkUrl(
              meeting.project,
              meeting.parent,
              meeting.meeting
            )
            if (linkUrl) {
              const meetingName = item.fields["title"]
              let name = `${meeting.parent.name}${
                meetingName === meeting.parent.name ? "" : " > " + meetingName
              }`
              name = name.replaceAll(
                state.searchQuery || "",
                `<b>${state.searchQuery}</b>`
              )
              acc.push({
                meeting: meeting,
                name,
                date: meeting.meeting.startTime.toDateJpString(),
                parentId: meeting.parent.id,
                id: meeting.meeting.id,
                summary: "",
                loadingSummary: true,
                linkUrl,
              } as SearchResult)
            } else {
              state.total -= 1
            }
          } else {
            state.total -= 1
          }
          return acc
        },
        []
      )
      return sortedItems
    }

    const submit = async () => {
      state.isSearching = true
      state.currentSearchQuery = state.searchQuery
      const projects = await getMyProjectsAsync()
      if (projects) {
        const results = await searchProjectMemosAsync(
          projects.map(p => p.project),
          state.searchQuery,
          state.page
        )
        state.total = results.value[0].hitsContainers[0].total
        const hits = results.value[0].hitsContainers[0].hits || []
        const resultKeyValue = hits.map(h => ({
          listId: h.resource.sharepointIds?.listId || "",
          id: h.resource.sharepointIds?.listItemId || "",
          summary: h.summary || "",
          fields: (h.resource.fields || {}) as { [key: string]: string },
        }))
        state.searchResults = getNextAndPastMeetingItems(
          projects,
          resultKeyValue
        )
        state.isSearching = false
        const values = (
          await Promise.all(
            state.searchResults.map(async currentMeeting => {
              const memo = await getMemoHtmlValue(
                currentMeeting.meeting.project.siteId,
                currentMeeting.meeting.project.sharepointListId,
                currentMeeting.meeting.meeting.sharepointItemId,
                currentMeeting.meeting.project.groupId
              )
              return { key: currentMeeting.meeting.meeting.id, memo }
            })
          )
        ).reduce((v, a) => {
          v[a.key] = a.memo
          return v
        }, {} as { [key: string]: string })
        state.searchResults = state.searchResults.map(v => {
          if (values[v.id]) {
            let summrayData = values[v.id].replace(/<[^>]*>/g, " ")
            const stringSpan = 200
            const trimingStart = Math.max(
              summrayData.indexOf(state.currentSearchQuery) - stringSpan,
              0
            )
            const trimingEnd = Math.min(
              summrayData.indexOf(state.currentSearchQuery) +
                state.currentSearchQuery.length +
                stringSpan,
              summrayData.length
            )
            const isEnded = trimingEnd === summrayData.length
            summrayData = summrayData.replaceAll(
              state.currentSearchQuery,
              `<b>${state.currentSearchQuery}</b>`
            )
            v.loadingSummary = false
            v.summary =
              (trimingStart === 0 ? "" : "...") +
              summrayData.substring(trimingStart, trimingEnd) +
              (isEnded ? "" : "...")
          } else {
            v.loadingSummary = false
            v.summary =
              '<label style="color:#AAA">(メモのデータがありません)</label>'
          }
          return v
        })
      }
    }

    const onMove = (link: string) => {
      microsoftTeams.openLink(link)
    }

    watch(
      () => state.page,
      () => {
        submit()
      }
    )

    onMounted(() => {
      if (state.searchQuery) {
        submit()
      }
    })

    return {
      state,
      submit,
      formatDateTime,
      onMove,
      i18nFormattedMessage: formatMessage,
    }
  },
})
